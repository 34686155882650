import { Typography } from "@mui/material";
import React, { useState } from "react";
import CustomToast from "../CustomToast";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { apiService } from "../../services/ApiService";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
      sx={{
        position: "fixed",
        bottom: 0,
        marginLeft: "50%",
        marginRight: "auto",
        transform: "translate(-50%, 0)",
      }}
    >
      {"Copyright © "}
      <a href={window?.location.href} style={{ textDecoration: "none" }}>
        {window?.location?.hostname || "Blue Bricks Licensing"}
      </a>
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const BlankLayout = ({ children }) => {
  const [sitekey, setKey] = useState(null);

  React.useEffect(() => {
    if (!sitekey) getKeys();
    return () => {};
  }, []);

  let loadingKeys = false;
  const getKeys = async () => {
    if (loadingKeys) return;
    loadingKeys = true;
    await apiService
      .get("authenticate/keys")
      .then((res) => res?.data || res)
      .then((response) => {
        const site_Key = atob(response?.data?.recaptcha_siteKey);
        setKey(site_Key);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        loadingKeys = false;
      });
  };
  if (!sitekey) {
    return <>Loading...</>;
  }
  return (
    <main className="">
      <CustomToast />
      <GoogleReCaptchaProvider reCaptchaKey={sitekey} useEnterprise={true}>
        {children}
      </GoogleReCaptchaProvider>
      <Copyright sx={{ my: 1 }} />
    </main>
  );
};

export default BlankLayout;
