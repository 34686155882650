import React from "react";
import {
  Button,
  Typography,
  Divider,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import { apiService } from "../../services/ApiService";

const useStyles = makeStyles((theme) => ({
  selectedRow: {
    backgroundColor: "#b4c9f4",
  },
}));

const defaultServerPackage = {
  title: "Server 1",
  pack: null,
  packageList: [],
};
function ProductSelection({
  isNew,
  noOfLicenses,
  selectedProduct,
  setSelectedProduct,
  allProductList,
  allPackageList,
  setAllPackageList,
  setServerList,
  displyLabel = true,
  currentCountry,
  setCurrentCountry,
  currentGeoLocation,
  setCurrentGeoLocation,
}) {
  const classes = useStyles();

  let isPackageListLoding = false;
  const handleProductSelect = (prodId, prodCode) => {
    if (isPackageListLoding) return;
    isPackageListLoding = true;
    apiService
      .get(`exposed/list/${prodCode}/packages?type=public&country=${currentCountry}`)
      .then((res) => res?.data || res)
      .then((response) => {
        toast.dismiss();
        if (response.resultCode > 0) {
          const list = response.data?.filter((e) => e?.isPublic);
          setAllPackageList(list || []);

          if (noOfLicenses == 0 || noOfLicenses == 1) {
            setServerList([{ ...defaultServerPackage, packageList: list || [] }]);
          } else if (noOfLicenses > 1) {
            let _serverPackages = [];
            for (let i = 0; i < noOfLicenses; i++) {
              _serverPackages.push({
                title: `Server ${i + 1}`,
                pack: null,
                packageList: list || [],
              });
            }
            setServerList(_serverPackages);
          }
        } else {
          toast.error(response.message || "Fail");
        }
      })
      .catch((err) => {
      });
  };

  return (
    <>
      <Divider sx={{ my: 1 }} />
      {displyLabel && (
        <>
          <Typography variant="h6" align="center">
            Selected Product
          </Typography>
          <Divider sx={{ my: 1 }} />
        </>
      )}

      <Grid spacing={3} sx={{ marginTop: "1rem" }}>
        {allProductList &&
          allProductList?.map((e, i) => (
            <Grid sm={12} md={12} key={`${i}_g1`}>
              <Card sx={{ display: "flex", width: "100%" }} key={`${i}_g1c`}>
                <CardActionArea
                  sx={{
                    backgroundColor: selectedProduct?._id == e?._id ? "#6b8bf8" : "#fff",
                    color: selectedProduct?._id == e?._id ? "#fff" : "#000",
                  }}
                  key={`${i}_g1caa`}
                >
                  <CardContent key={`${i}_g1cca`}>
                    <Typography gutterBottom variant="h6" component="div">
                      Product: {e?.productName}
                    </Typography>

                    <Grid container spacing={2} sx={{ paddingX: "1rem", marginY: "1rem" }} key={`${i}_g1ccag2`}>
                      <Grid sm={12} md={4} xs={12} lg={4} key={`${i}_g1ccag3`}>
                        <Typography gutterBottom variant="body1" component="div">
                          Product Code: <b>{e?.productCode}</b>
                        </Typography>
                      </Grid>
                      <Grid sm={12} md={4} xs={12} lg={4} key={`${i}_g1ccag4`}>
                        {e?.productLink && e?.productLink?.includes("http") && <Typography gutterBottom variant="body2" component="div">
                          To view/visit product{" "}
                          <a href={new URL(e?.productLink?.toString())} target="_blank">
                            {" "}
                            click here
                          </a>
                        </Typography>}
                      </Grid>
                    </Grid>

                    <Typography variant="body2">
                      Description: <b>{e?.description}</b>
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions key={`${i}_cas`}>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      handleProductSelect(e?._id, e?.productCode);
                      setSelectedProduct(e);
                    }}
                    disabled={selectedProduct?._id == e?._id}
                  >
                    {selectedProduct?._id == e?._id ? "Selected" : "Select"}
                  </Button>
                </CardActions>
              </Card>
              <Divider sx={{ my: 1 }} />
            </Grid>
          ))}
      </Grid>

      {/* ); */}
      {/* })} */}
      {/* </TableBody>
        </Table>
      </TableContainer> */}
    </>
  );
}

export default ProductSelection;
