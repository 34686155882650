import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

axios.defaults.withCredentials = true;
axios.defaults.credentials = "include";

let Hosted_Base_URL = window?.location?.origin;

const API_BASE_URL = process.env?.REACT_APP_API_BASE_URL || `${Hosted_Base_URL}/api`;

const apiService = axios.create({ baseURL: `${API_BASE_URL}/` });

// Add a request interceptor
apiService.interceptors.request.use(
  function (config) {
    // Append opkey as a query parameter to each request
    const opkey = sessionStorage.getItem("opkey");
    if (opkey) {
      config.params = { ...config.params, opkey };
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiService.interceptors.response.use(
  (response) => {
    // toast.dismiss();
    return Promise.resolve(response);
  },
  (error) => {
    toast.dismiss();
    if (error?.status === 401) {
      toast.error("Unauthorized request! Session expired!");
      sessionStorage.clear();
      localStorage.clear();

      const allCookies = Cookies.get();
      Object.keys(allCookies).forEach((cookie) => Cookies.remove(cookie));

      window.location.replace("/");
      return Promise.reject(`Unauthorized request! Session expired!`);
    }
    console.debug("API SERVICE :: ", error);

    if (error?.response?.data) {
      let api_res = error?.response?.data;
      // console.log({ api_res });
      let message = (
        <>
          <span>{api_res?.message}</span>
          <br />
          <span style={{ fontSize: "13px", fontWeight: "bold", marginTop: "1rem" }}>
            {`Error code: ${api_res?.code}`}{" "}
          </span>
        </>
      );

      toast.error(message);
    } else if (error?.code === "ERR_NETWORK") {
      let message = (
        <>
          <span>{"A network error occurred!"}</span>
          <br />
          <span>{"*Possible causes:"}</span>
          <br />
          <span>{"- Backend down."}</span>
          <br />
          <span>{"- CORS misconfiguration."}</span>
          <br />
          <span>{"- Too many requests."}</span>
          <br />
          <br />
          <span style={{ fontSize: "13px", fontWeight: "bold", marginTop: "1rem" }}>{`Error code: ERRxCONN001`} </span>
        </>
      );

      toast.error(message, {
        autoClose: 5000,
      });
    }else if (error?.code === "ERR_CONNECTION_REFUSED") {
      let message = (
        <>
          <span>{"A network error occurred!"}</span>
          <br />
          <br />
          <span>{"Possible causes:"}</span>
          <br />
          <span>{"- Backend down."}</span>
          <br />
          <span>{"- Too many requests."}</span>
          <br />
          <br />
          <span style={{ fontSize: "13px", fontWeight: "bold", marginTop: "1rem" }}>{`Error code: ERRxCONN001`} </span>
        </>
      );

      toast.error(message, {
        autoClose: 5000,
      });
    }
    // For all other errors, just reject
    return Promise.reject(error);
  }
);
export { apiService };
