import {
  Box,
  Button,
  Container,
  CssBaseline,
  Divider,
  Grid,
  InputLabel,
  Paper,
  Typography,
  FormControl,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { apiService } from "../../services/ApiService";
import { toast } from "react-toastify";
import {  useParams } from "react-router";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const PasswordSet = () => {
  
const { executeRecaptcha } = useGoogleReCaptcha();

  const { userId } = useParams();
  const [sessionToken, setSessionToken] = useState(null);
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [inValidPassword, isInValidPassword] = useState(false);
  const [inValidPassword2, isInValidPassword2] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);
  const [submited, setSubmited] = React.useState(false);

  useEffect(() => {
    const currentUrl = window.location.href;

    const url = new URL(currentUrl);
    const opkey = url.searchParams.get("opkey");
    const token = url.searchParams.get("token");

    setSessionToken(token);
    sessionStorage.setItem("opkey", opkey);
  }, []);

  useEffect(() => {
    if (sessionToken) checkToken();
  }, [sessionToken]);

  const checkToken = () => {
    try {
      apiService
        .get(`authenticate/token/${sessionToken}/verify`)
        .then((res) => res?.data || res)
        .then((response) => {
          console.log({ response });
          toast.dismiss();
          if (response.resultCode > 0) {
            toast.success(response.message);
          } else {
            toast.error(response.message || "Fail");
            console.log("ERROR::::");
          }
        })
        .catch((err) => {
          // toast.dismiss();
          // if (err?.response && err?.response?.data) {
          //   toast.error(err?.response?.data?.message);
          // }

          sessionStorage.removeItem("opkey");
          setTimeout(() => {
            window.location.href = "/";
          }, 1500);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (executeRecaptcha) {
      console.log("reCAPTCHA is ready to use");
    }
  }, [executeRecaptcha]);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      if (!executeRecaptcha) {
        return;
      }
      const recaptchaToken = await executeRecaptcha("password_reset");
  
  
      if (!password || !cPassword) {
        toast.warn("All fields are required!");
        return;
      }
      if (password.length < 6 || password.length > 12) {
        isInValidPassword(true);
        toast.warn("Password length should be min 6 and max 12 character long!");
        return;
      }
      if (password.trim() != cPassword.trim()) {
        isInValidPassword2(true);
        toast.warn("New Password and Re-entered password does not match!");
        return;
      }
      const formData = {
        userId: userId,
        password: password.trim(),
        token: sessionToken,
        recaptchaToken
      };

      // console.log({ formData });

      if (!formData.userId || !formData.password) {
        return;
      }
      setSubmited(true);

      apiService
        .post("authenticate/operator/setPassword", formData)
        .then((res) => res?.data || res)
        .then((response) => {
          toast.dismiss();
          if (response.resultCode > 0) {
            toast.success(response.message);
            sessionStorage.removeItem("opkey");
            setTimeout(() => {
              window.location.href = "/";
            }, 1500);
          } else {
            toast.error(response.message || "Fail");
            setSubmited(false);
          }
        })
        .catch((err) => {
          setSubmited(false);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword2 = () => setShowPassword2((show) => !show);
  const handleMouseDownPassword2 = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <form onSubmit={handleSubmit}>
          <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img src="/Valydate4u.png" style={{ margin: "auto 0.5rem", width: "16rem", height: "100%" }} />
            </Box>
            <Typography component="h5" variant="h5" align="center">
              Set New Password and Activate
            </Typography>

            <Divider sx={{ my: 2 }} />
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth variant="outlined" error={inValidPassword}>
                  <InputLabel htmlFor="password">New Password</InputLabel>
                  <OutlinedInput
                    fullWidth
                    required
                    name="password"
                    id="password"
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => {
                      const newPassword = e.target.value;
                      setPassword(newPassword);
                      isInValidPassword(newPassword.length > 12);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="New Password"
                    inputProps={{ maxLength: 12, minLength: 6 }}
                  />
                  <FormHelperText>password length minimum 6 and maximum 12 characters long</FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormControl fullWidth variant="outlined" error={inValidPassword2}>
                  <InputLabel htmlFor="cpassword">Re-enter Password</InputLabel>
                  <OutlinedInput
                    fullWidth
                    required
                    name="cpassword"
                    id="cpassword"
                    type={showPassword2 ? "text" : "password"}
                    value={cPassword}
                    onChange={(e) => {
                      isInValidPassword2(false);
                      setCPassword(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword2}
                          edge="end"
                        >
                          {showPassword2 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Re-enter Password"
                    inputProps={{ maxLength: 12, minLength: 6 }}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="contained" type="submit" sx={{ mt: 3, ml: 1 }} disabled={submited}>
                Set Password & Activate
              </Button>
            </Box>
          </Paper>
        </form>
      </Container>
    </>
  );
};

export default PasswordSet;
