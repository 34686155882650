const InitCategory = "Valydate4u Access";
const InitSuccess = "Success";

const LoginCategory = "Login";
const LoginActionSuccess = "Success";
const LoginActionFail = "Fail";

const RegisterCategory = "Register";
const RegisterActionSuccess = "Success";
const RegisterActionFail = "Fail";

const NewLicenseRequestCategory = "New License Request";
const NewLicenseRequestSuccess = "Success";
const NewLicenseRequestFail = "Fail";

const ReNewLicenseRequestCategory = "Re-New License Request";
const ReNewLicenseRequestSuccess = "Success";
const ReNewLicenseRequestFail = "Fail";

const AnalyticsCategory = {
  InitCategory,
  InitSuccess,
  LoginCategory,
  LoginActionSuccess,
  LoginActionFail,
  RegisterCategory,
  RegisterActionSuccess,
  RegisterActionFail,
  NewLicenseRequestCategory,
  NewLicenseRequestSuccess,
  NewLicenseRequestFail,
  ReNewLicenseRequestCategory,
  ReNewLicenseRequestSuccess,
  ReNewLicenseRequestFail,
};

export default AnalyticsCategory;
