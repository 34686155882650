import React from "react";
import { Grid, Box, Typography, Button } from "@mui/material";

const PasswordSetSuccess = () => {
  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        backgroundColor: "#ffffff",
        my:5
      }}
    >
      <Grid item xs={12} sm={12} md={12} lg={10} sx={{ mb: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            px: 4,
          }}
        >
          <Typography variant="h5" sx={{ mt: 3, fontWeight: "bold" }}>
            {"Password set successfully."}
          </Typography>

          <Typography variant="h5" sx={{ mt: 2, fontWeight: "bold" }}>
            {"Click on below button and sign in."}
          </Typography>

          <Button
            variant="outlined"
            color="warning"
            sx={{ my: 3 }}
            onClick={(e) => {
              e.preventDefault();

              window.location.href = `${window.location.origin}`;
            }}
          >
            Back to Sign-in
          </Button>
          <img src="./LockSync.png" width="350px" />
        </Box>
      </Grid>
    </Grid>
  );
};

export default PasswordSetSuccess;
