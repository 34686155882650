import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { apiService } from "../../services/ApiService";
import { toast } from "react-toastify";
import {  Launch, LoginOutlined } from "@mui/icons-material";

import { isValidPhoneNumber } from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/material.css";
import countriesList from "../../utils/countries";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics.service";
import AnalyticsCategory from "../../utils/AnalyticsCategory";

export const RegistrationPage = ({ setLoginRegPage }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const initialValue = {
    fullName: "",
    emailId: "",
    mobile: "",
    designation: "",
    orgName: "",
    ipAddress: "",
    companyName: "",
    country: "",
    companyWebsite: "",
    industry: "",
    companySize: "",
    linkedInProfile: "",
    isFollowupCall: false,
    isUniversityConnection: false,
    isAgree: false,
  };

  const [emailError, setEmailError] = useState(false);
  const [user, setUser] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [inUrlError, setInUrlError] = useState(false);
  const [lat, setLat] = useState(0);
  const [long, setLong] = useState(0);

  const titleList = [
    {
      label: "Mr.",
      value: "Mr.",
    },
    {
      label: "Mrs.",
      value: "Mrs.",
    },
  ];

  const companySizeList = [
    {
      label: "1-10",
      value: "1-10",
    },
    {
      label: "11-50",
      value: "11-50",
    },
    {
      label: "51-200",
      value: "51-200",
    },
    {
      label: "201-1000",
      value: "201-1000",
    },
    {
      label: "1000+",
      value: "1000+",
    },
  ];

  const designationList = [
    {
      label: "C-Level Management",
      value: "C-Level Management",
    },
    {
      label: "V-level management",
      value: "V-level management",
    },
    {
      label: "D-level management",
      value: "D-level management",
    },
    {
      label: "B-level management",
      value: "B-level management",
    },
  ];

  const industryList = [
    {
      label: "Accounting",
      value: "Accounting",
    },
    {
      label: "Airlines/Aviation",
      value: "Airlines/Aviation",
    },
    {
      label: "Alternative Dispute Resolution",
      value: "Alternative Dispute Resolution",
    },
    {
      label: "Alternative Medicine",
      value: "Alternative Medicine",
    },
    {
      label: "Animation",
      value: "Animation",
    },
    {
      label: "Apparel & Fashion",
      value: "Apparel & Fashion",
    },
    {
      label: "Architecture & Planning",
      value: "Architecture & Planning",
    },
    {
      label: "Arts & Crafts",
      value: "Arts & Crafts",
    },
    {
      label: "Automotive",
      value: "Automotive",
    },
    {
      label: "Aviation & Aerospace",
      value: "Aviation & Aerospace",
    },
    {
      label: "Banking",
      value: "Banking",
    },
    {
      label: "Biotechnology",
      value: "Biotechnology",
    },
    {
      label: "Broadcast Media",
      value: "Broadcast Media",
    },
    {
      label: "Building Materials",
      value: "Building Materials",
    },
    {
      label: "Business Supplies & Equipment",
      value: "Business Supplies & Equipment",
    },
    {
      label: "Capital Markets",
      value: "Capital Markets",
    },
    {
      label: "Chemicals",
      value: "Chemicals",
    },
    {
      label: "Civic & Social Organization",
      value: "Civic & Social Organization",
    },
    {
      label: "Civil Engineering",
      value: "Civil Engineering",
    },
    {
      label: "Commercial Real Estate",
      value: "Commercial Real Estate",
    },
    {
      label: "Computer & Network Security",
      value: "Computer & Network Security",
    },
    {
      label: "Computer Games",
      value: "Computer Games",
    },
    {
      label: "Computer Hardware",
      value: "Computer Hardware",
    },
    {
      label: "Computer Networking",
      value: "Computer Networking",
    },
    {
      label: "Computer Software",
      value: "Computer Software",
    },
    {
      label: "Construction",
      value: "Construction",
    },
    {
      label: "Consumer Electronics",
      value: "Consumer Electronics",
    },
    {
      label: "Consumer Goods",
      value: "Consumer Goods",
    },
    {
      label: "Consumer Services",
      value: "Consumer Services",
    },
    {
      label: "Cosmetics",
      value: "Cosmetics",
    },
    {
      label: "Dairy",
      value: "Dairy",
    },
    {
      label: "Defense & Space",
      value: "Defense & Space",
    },
    {
      label: "Design",
      value: "Design",
    },
    {
      label: "Education Management",
      value: "Education Management",
    },
    {
      label: "E-learning",
      value: "E-learning",
    },
    {
      label: "Electrical & Electronic Manufacturing",
      value: "Electrical & Electronic Manufacturing",
    },
    {
      label: "Entertainment",
      value: "Entertainment",
    },
    {
      label: "Environmental Services",
      value: "Environmental Services",
    },
    {
      label: "Events Services",
      value: "Events Services",
    },
    {
      label: "Executive Office",
      value: "Executive Office",
    },
    {
      label: "Facilities Services",
      value: "Facilities Services",
    },
    {
      label: "Farming",
      value: "Farming",
    },
    {
      label: "Financial Services",
      value: "Financial Services",
    },
    {
      label: "Fine Art",
      value: "Fine Art",
    },
    {
      label: "Fishery",
      value: "Fishery",
    },
    {
      label: "Food & Beverages",
      value: "Food & Beverages",
    },
    {
      label: "Food Production",
      value: "Food Production",
    },
    {
      label: "Fundraising",
      value: "Fundraising",
    },
    {
      label: "Furniture",
      value: "Furniture",
    },
    {
      label: "Gambling & Casinos",
      value: "Gambling & Casinos",
    },
    {
      label: "Glass, Ceramics & Concrete",
      value: "Glass, Ceramics & Concrete",
    },
    {
      label: "Government Administration",
      value: "Government Administration",
    },
    {
      label: "Government Relations",
      value: "Government Relations",
    },
    {
      label: "Graphic Design",
      value: "Graphic Design",
    },
    {
      label: "Health, Wellness & Fitness",
      value: "Health, Wellness & Fitness",
    },
    {
      label: "Higher Education",
      value: "Higher Education",
    },
    {
      label: "Hospital & Health Care",
      value: "Hospital & Health Care",
    },
    {
      label: "Hospitality",
      value: "Hospitality",
    },
    {
      label: "Human Resources",
      value: "Human Resources",
    },
    {
      label: "Import & Export",
      value: "Import & Export",
    },
    {
      label: "Individual & Family Services",
      value: "Individual & Family Services",
    },
    {
      label: "Industrial Automation",
      value: "Industrial Automation",
    },
    {
      label: "Information Services",
      value: "Information Services",
    },
    {
      label: "Information Technology & Services",
      value: "Information Technology & Services",
    },
    {
      label: "Insurance",
      value: "Insurance",
    },
    {
      label: "International Affairs",
      value: "International Affairs",
    },
    {
      label: "International Trade & Development",
      value: "International Trade & Development",
    },
    {
      label: "Internet",
      value: "Internet",
    },
    {
      label: "Investment Banking/Venture",
      value: "Investment Banking/Venture",
    },
    {
      label: "Investment Management",
      value: "Investment Management",
    },
    {
      label: "Judiciary",
      value: "Judiciary",
    },
    {
      label: "Law Enforcement",
      value: "Law Enforcement",
    },
    {
      label: "Law Practice",
      value: "Law Practice",
    },
    {
      label: "Legal Services",
      value: "Legal Services",
    },
    {
      label: "Legislative Office",
      value: "Legislative Office",
    },
    {
      label: "Leisure & Travel",
      value: "Leisure & Travel",
    },
    {
      label: "Libraries",
      value: "Libraries",
    },
    {
      label: "Logistics & Supply Chain",
      value: "Logistics & Supply Chain",
    },
    {
      label: "Luxury Goods & Jewelry",
      value: "Luxury Goods & Jewelry",
    },
    {
      label: "Machinery",
      value: "Machinery",
    },
    {
      label: "Management Consulting",
      value: "Management Consulting",
    },
    {
      label: "Maritime",
      value: "Maritime",
    },
    {
      label: "Marketing & Advertising",
      value: "Marketing & Advertising",
    },
    {
      label: "Market Research",
      value: "Market Research",
    },
    {
      label: "Mechanical or Industrial Engineering",
      value: "Mechanical or Industrial Engineering",
    },
    {
      label: "Media Production",
      value: "Media Production",
    },
    {
      label: "Medical Device",
      value: "Medical Device",
    },
    {
      label: "Medical Practice",
      value: "Medical Practice",
    },
    {
      label: "Mental Health Care",
      value: "Mental Health Care",
    },
    {
      label: "Military",
      value: "Military",
    },
    {
      label: "Mining & Metals",
      value: "Mining & Metals",
    },
    {
      label: "Motion Pictures & Film",
      value: "Motion Pictures & Film",
    },
    {
      label: "Museums & Institutions",
      value: "Museums & Institutions",
    },
    {
      label: "Music",
      value: "Music",
    },
    {
      label: "Nanotechnology",
      value: "Nanotechnology",
    },
    {
      label: "Newspapers",
      value: "Newspapers",
    },
    {
      label: "Nonprofit Organization Management",
      value: "Nonprofit Organization Management",
    },
    {
      label: "Oil & Energy",
      value: "Oil & Energy",
    },
    {
      label: "Online Publishing",
      value: "Online Publishing",
    },
    {
      label: "Outsourcing/Offshoring",
      value: "Outsourcing/Offshoring",
    },
    {
      label: "Package/Freight Delivery",
      value: "Package/Freight Delivery",
    },
    {
      label: "Packaging & Containers",
      value: "Packaging & Containers",
    },
    {
      label: "Paper & Forest Products",
      value: "Paper & Forest Products",
    },
    {
      label: "Performing Arts",
      value: "Performing Arts",
    },
    {
      label: "Pharmaceuticals",
      value: "Pharmaceuticals",
    },
    {
      label: "Philanthropy",
      value: "Philanthropy",
    },
    {
      label: "Photography",
      value: "Photography",
    },
    {
      label: "Plastics",
      value: "Plastics",
    },
    {
      label: "Political Organization",
      value: "Political Organization",
    },
    {
      label: "Primary/Secondary",
      value: "Primary/Secondary",
    },
    {
      label: "Printing",
      value: "Printing",
    },
    {
      label: "Professional Training",
      value: "Professional Training",
    },
    {
      label: "Program Development",
      value: "Program Development",
    },
    {
      label: "Public Policy",
      value: "Public Policy",
    },
    {
      label: "Public Relations",
      value: "Public Relations",
    },
    {
      label: "Public Safety",
      value: "Public Safety",
    },
    {
      label: "Publishing",
      value: "Publishing",
    },
    {
      label: "Railroad Manufacture",
      value: "Railroad Manufacture",
    },
    {
      label: "Ranching",
      value: "Ranching",
    },
    {
      label: "Real Estate",
      value: "Real Estate",
    },
    {
      label: "Recreational",
      value: "Recreational",
    },
    {
      label: "Facilities & Services",
      value: "Facilities & Services",
    },
    {
      label: "Religious Institutions",
      value: "Religious Institutions",
    },
    {
      label: "Renewables & Environment",
      value: "Renewables & Environment",
    },
    {
      label: "Research",
      value: "Research",
    },
    {
      label: "Restaurants",
      value: "Restaurants",
    },
    {
      label: "Retail",
      value: "Retail",
    },
    {
      label: "Security & Investigations",
      value: "Security & Investigations",
    },
    {
      label: "Semiconductors",
      value: "Semiconductors",
    },
    {
      label: "Shipbuilding",
      value: "Shipbuilding",
    },
    {
      label: "Sporting Goods",
      value: "Sporting Goods",
    },
    {
      label: "Sports",
      value: "Sports",
    },
    {
      label: "Staffing & Recruiting",
      value: "Staffing & Recruiting",
    },
    {
      label: "Supermarkets",
      value: "Supermarkets",
    },
    {
      label: "Telecommunications",
      value: "Telecommunications",
    },
    {
      label: "Textiles",
      value: "Textiles",
    },
    {
      label: "Think Tanks",
      value: "Think Tanks",
    },
    {
      label: "Tobacco",
      value: "Tobacco",
    },
    {
      label: "Translation & Localization",
      value: "Translation & Localization",
    },
    {
      label: "Transportation/Trucking/Railroad",
      value: "Transportation/Trucking/Railroad",
    },
    {
      label: "Utilities",
      value: "Utilities",
    },
    {
      label: "Venture Capital",
      value: "Venture Capital",
    },
    {
      label: "Veterinary",
      value: "Veterinary",
    },
    {
      label: "Warehousing",
      value: "Warehousing",
    },
    {
      label: "Wholesale",
      value: "Wholesale",
    },
    {
      label: "Wine & Spirits",
      value: "Wine & Spirits",
    },
    {
      label: "Wireless",
      value: "Wireless",
    },
    {
      label: "Writing & Editing",
      value: "Writing & Editing",
    },
  ];

  useEffect(() => {
    const islogin = sessionStorage.getItem("isLogin") ? true : false;
    const opkey_id = sessionStorage.getItem("opkey");
    if (islogin && opkey_id) {
      window.location.href = "/dashboard";
    } else {
      sessionStorage.removeItem("isLogin");
    }
  }, []);

  const setCoordinates = (position) => {
    setLat(position?.coords?.latitude);
    setLong(position?.coords?.longitude);
  };

  const detectDevice = async () => {
    try {
      const deviceData = navigator?.userAgentData || navigator?.platform;
      const brands = deviceData?.brands && deviceData?.brands[0] ? deviceData?.brands[0] : {};
      let device = `${deviceData?.platform || ""} ${brands?.brand ? "- " + brands?.brand : ""} ${
        brands?.version ? "v" + brands?.version : ""
      }`;

      return device;
    } catch (error) {
      console.log(error);
      return "";
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (navigator.geolocation) {
      await navigator.geolocation.getCurrentPosition(setCoordinates);
    }

    if (!executeRecaptcha) {
      return;
    }
    const recaptchaToken = await executeRecaptcha("signup");

    if (urlError) {
      toast.error(`Please enter correct official website!`);
      return;
    }
    if (user.linkedInProfile && inUrlError) {
      toast.error(`Please enter correct company linkedin profile!`);
      return;
    }
    let fullName = user?.fullName;

    if (fullName) {
      fullName = fullName.split(" ");
    }

    if (!user?.mobile) {
      toast.error(`Please enter valid contact/mobile number!`);
      return;
    }

    const _mobile = "+" + user?.mobile;
    if (!isValidPhoneNumber(_mobile)) {
      toast.error(`Invalid contact/mobile number!`);
      return;
    }

    let body = {
      ...user,
      mobile: _mobile,
      firstName: fullName[0] ? fullName[0] : "",
      lastName: fullName[1] ? fullName?.slice(1)?.join(" ") : "",
      ipAddress: "",
      device: navigator.userAgentData ? await detectDevice() : "",
      latitude: lat || 0,
      longitude: long || 0,
      recaptchaToken,
    };

    if (!body.firstName || !body.emailId) {
      toast.warn("Please fill the required fields!");
      return;
    }

    toast.loading("Processing...");
    setLoading(true);

    apiService
      .post("operator/register", body)
      .then((res) => res?.data || res)
      .then((response) => {
        toast.dismiss();
        
        
        TrackGoogleAnalyticsEvent(
          AnalyticsCategory.RegisterCategory,
          AnalyticsCategory.RegisterActionSuccess,
          window?.location?.pathname || '',
        );

        if (response.resultCode > 0) {
          window.location.href = `${window.location.origin}/auth-success-notify?mid=REG_SUCCESS`;
        } else {
          toast.error(response.message || "Fail");
        }
      })
      .catch((err) => {
        
        TrackGoogleAnalyticsEvent(
          AnalyticsCategory.RegisterCategory,
          AnalyticsCategory.RegisterActionFail,
          window?.location?.pathname || '',
        );
      }).finally(()=>{setLoading(false);});
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <img src="/Valydate4u.png" style={{ margin: "auto 0.5rem", width: "16rem", height: "100%" }} />
        {/* <span>
          {" "}
          <Typography component="h1" variant="h6">
            Licensing
          </Typography>
          <Typography variant="caption">Welcome to Licensing.</Typography>
        </span> */}
      </Box>
      <Typography component="h1" variant="h6">
        Create your free account
      </Typography>
      {/* <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}> */}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            margin="none"
            required
            fullWidth
            id="fullName"
            label="Full Name"
            name="fullName"
            helperText="First Name and Last Name only"
            autoFocus
            type="text"
            value={user.fullName}
            inputProps={{ maxLength: 45 }}
            onChange={(e) => {
              let input = e.target.value.replace(/[^A-Za-z\s]/g, "").replace(/\s+/g, " ");

              setUser({
                ...user,
                fullName: input,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="emailId"
            label="Business Email Address"
            name="emailId"
            type="email"
            value={user.emailId}
            helperText={emailError ? "Please enter valid email id." : ""}
            error={emailError}
            onChange={(e) => {
              let input = e.target.value
                .replace(/[^A-Za-z0-9\s\.@]/g, "")
                .replace(/\s+/g, " ")
                .replace(/^\./g, "")
                .replace(/@+/g, "@")
                .replace(/\.\.+/g, ".");

              setUser({
                ...user,
                emailId: input,
              });

              const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

              if (emailRegex.test(input || "")) {
                setEmailError(false);
              } else {
                setEmailError(true);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box sx={{ display: "flex", alignItems: "flex-end", marginTop: "1rem" }}>
            <PhoneInput
              specialLabel="Best Contact Number"
              country={"us"}
              inputProps={{
                name: "phoneNumber",
                required: true,
                autoFocus: true,
                style: {
                  height: 56,
                  width: "100%",
                  borderColor: "#ccc",
                },
              }}
              value={user?.mobile}
              onChange={(phoneNumber, country) => {
                setUser({
                  ...user,
                  mobile: phoneNumber,
                });
              }}
            />
          </Box>{" "}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            name="designation"
            required
            // onChange={saveState}
            label="Your Designation"
            // value={user?.designation}
            variant="outlined"
            fullWidth
            select
            onChange={(e) => {
              setUser({
                ...user,
                designation: e.target.value,
              });
            }}
          >
            {designationList.map((item, index) => (
              <MenuItem key={index} value={item?.value}>
                {item?.label}
              </MenuItem>
            ))}
          </TextField>{" "}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            type="text"
            inputProps={{ maxLength: 55 }}
            placeholder="ABC LLC"
            name="companyName"
            required
            value={user?.companyName}
            label="Company Name"
            variant="outlined"
            fullWidth
            onChange={(e) => {
              let input = e.target.value
                .replace(/[^A-Za-z0-9\s\.\&]/g, "")
                .replace(/\s+/g, " ")
                .replace(/\.+/g, ".")
                .replace(/\&+/g, "&");
              setUser({
                ...user,
                companyName: input,
              });
            }}
          />{" "}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            type="url"
            inputProps={{ maxLength: 55 }}
            placeholder="www.example.com"
            name="url"
            value={user?.companyWebsite}
            onChange={(e) => {
              let input = e.target.value.replace(/[^A-Za-z0-9\./]/g, "").replace(/\s+/g, " ");

              if (input.includes("http") || input.startsWith("/")) {
                setUrlError(true);
              } else {
                setUrlError(false);
              }
              setUser({
                ...user,
                companyWebsite: input,
              });
            }}
            required
            label="Official Website"
            variant="outlined"
            helperText={
              user?.companyWebsite &&
              (user?.companyWebsite.includes("http") ||
                user?.companyWebsite.startsWith("/") ||
                !user?.companyWebsite.includes(".")) ? (
                <Typography variant="caption" color="error">
                  URL should be 'example.com' or 'www.example.com' or 'example.com/home', should not contain 'http' in
                  url.
                </Typography>
              ) : (
                ""
              )
            }
            error={urlError}
            fullWidth
          />{" "}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            type="text"
            placeholder="India"
            name="country"
            value={user?.country}
            required
            label="Country"
            variant="outlined"
            fullWidth
            select
            onChange={(e) => {
              setUser({
                ...user,
                country: e.target.value,
              });
            }}
          >
            {countriesList.map((item, index) => (
              <MenuItem key={index} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>{" "}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            type="number"
            placeholder="10"
            name="size"
            required
            value={user?.companySize}
            onChange={(e) => {
              setUser({
                ...user,
                companySize: e.target.value,
              });
            }}
            label="Size(employees)"
            variant="outlined"
            fullWidth
            select
          >
            {companySizeList.map((item, index) => (
              <MenuItem key={index} value={item?.value}>
                {item?.label}
              </MenuItem>
            ))}
          </TextField>{" "}
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextField
            name="industry"
            required
            onChange={(e) => {
              setUser({
                ...user,
                industry: e.target.value,
              });
            }}
            value={user?.industry}
            label="Industry"
            variant="outlined"
            fullWidth
            select
          >
            {industryList.map((item, index) => (
              <MenuItem key={index} value={item?.value}>
                {item?.label}
              </MenuItem>
            ))}
          </TextField>{" "}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <TextField
            type="url"
            inputProps={{ maxLength: 55 }}
            placeholder="www.linkedin.com/..."
            name="linkedinUrl"
            onChange={(e) => {
              let input = e.target.value;

              if (!input || input == "") setInUrlError(false);
              else if (
                input.includes("http") ||
                (!input.includes("linkedin.com") &&
                  (!input.startsWith("www.linkedin") || !input.startsWith("linkedin"))) ||
                !input.includes(".")
              ) {
                setInUrlError(true);
              } else {
                setInUrlError(false);
              }

              setUser({
                ...user,
                linkedInProfile: input,
              });
            }}
            value={user?.linkedInProfile}
            label="Linkedin Profile"
            variant="outlined"
            fullWidth
            helperText={
              user?.linkedInProfile &&
              (user?.linkedInProfile.includes("http") ||
                (!user?.linkedInProfile.includes("linkedin.com/") &&
                  (!user?.linkedInProfile.startsWith("www.linkedin") ||
                    !user?.linkedInProfile.startsWith("linkedin"))) ||
                !user?.linkedInProfile.includes(".")) ? (
                <Typography variant="caption" color="error">
                  Please provide correct company linkedin profile url, should be 'linkedin.com/...' or
                  'www.linkedin.com/...',it should not contain 'http' in url.
                </Typography>
              ) : (
                ""
              )
            }
            error={inUrlError}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  setUser({
                    ...user,
                    isUniversityConnection: e.target.checked,
                  });
                }}
                checked={user?.isUniversityConnection}
                name="isUniversityConnection"
              />
            }
            label="Do you have university connection?"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  setUser({
                    ...user,
                    isFollowupCall: e.target.checked,
                  });
                }}
                checked={user?.isFollowupCall}
                name="isFollowUpCall"
              />
            }
            label="Do you want follow up call?"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div style={{ marginLeft: "-0.7rem" }}>
            <Checkbox
              onChange={(e) => {
                setUser({
                  ...user,
                  isAgree: e.target.checked,
                });
              }}
              checked={user?.isAgree}
            />{" "}
            By checking this box, you are agreeing to our
            <Button
              // onClick={handleOpenModal}
              style={{ textTransform: "capitalize" }}
              color="primary"
              endIcon={<Launch fontSize="small"></Launch>}
              onClick={(e) => {
                e.preventDefault();
                window.open(`${window.location.origin}/privacy-policy`, "_blank");
              }}
            >
              {" "}
              Privacy Policy
            </Button>{" "}
            AND
            <Button
              // onClick={handleOpenModal}
              style={{ textTransform: "capitalize" }}
              color="primary"
              endIcon={<Launch fontSize="small"></Launch>}
              onClick={(e) => {
                e.preventDefault();
                window.open(`${window.location.origin}/terms-and-conditions`, "_blank");
              }}
            >
              {" "}
              Terms & Conditions
            </Button>
          </div>
        </Grid>

        <Button
          disabled={loading || !user?.isAgree}
          type="button"
          onClick={handleSubmit}
          fullWidth
          variant="contained"
          sx={{ mt: 2 }}
        >
          Sign Up
        </Button>
      </Grid>
      {/* <Grid container>
        <Grid item xs>
          <Link
            href="#"
            variant="body2"
            onClick={() => {
              window.location.href = "/forgotpassword";
            }}
          >
            Forgot password?
          </Link>
        </Grid>
        <Grid item>
          <Link
            href="#"
            variant="body2"
            onClick={() => {
              setLoginRegPage(false);
            }}
          >
            {"Already have an account? Sign In"}
          </Link>
        </Grid>
      </Grid> */}
      <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", mt: 3 }}>
        <Typography variant="p">If you have an account please click below to sign in.</Typography>
        <Button
          type="button"
          sx={{ width: "auto", mt: 2 }}
          variant="outlined"
          startIcon={<LoginOutlined />}
          onClick={() => {
            setLoginRegPage(false);
          }}
        >
          Go To Sign In
        </Button>
      </Box>
      {/* </Box> */}
    </>
  );
};
