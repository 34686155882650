import React, {  useState } from "react";
import {
  Button,
  Typography,
  Grid,
  Divider,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles((theme) => ({
  selectedRow: {
    backgroundColor: "#b4c9f4",
  },
}));



function PackageSelect({
  selectedProduct,
  noOfLicenses,
  serverList,
  setServerList,
  displyLabel = true,
  currentCountry,
  setCurrentCountry,
  currentGeoLocation,
  setCurrentGeoLocation,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [expanded, setExpanded] = useState(0);

  const handlePackageSelect = (serverListIndex, packdata) => {
    // console.log({ serverListIndex, packdata });
    let _serList = serverList;
    let selected_Pack = {
      ...packdata,
      shortDesc: `${packdata?.name} - ${getCurrencySymbol(packdata?.currencyCode) || "$"}${
        packdata?.price || 0.0
      } for ${packdata?.duration === "Custom"?`${packdata?.durationDays || 0 } Days`:packdata?.duration || "Monthly"}`,
    };
    _serList[serverListIndex].pack = selected_Pack;
    setServerList([..._serList]);
  };

  function getCurrencySymbol(currency, locale = "en-us") {
    if (!currency) {
      return "$";
    }
    return (0)
      .toLocaleString(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, "")
      .trim();
  }

  return (
    <>
      <Divider sx={{ my: 1 }} />

      {displyLabel && (
        <>
          <Typography variant="h6" align="center">
            Selected Product
          </Typography>
        </>
      )}

      <Grid sm={12} md={12}>
        <Card sx={{ display: "flex", width: "100%" }}>
          <CardActionArea
            sx={{
              backgroundColor: "#6a6a6d",
              color: "#fff",
            }}
          >
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                Product: {selectedProduct?.productName}
              </Typography>

              <Grid container spacing={2} sx={{ paddingX: "1rem", marginY: "1rem" }}>
                <Grid sm={12} md={4} xs={12} lg={4}>
                  <Typography gutterBottom variant="body1" component="div">
                    Product Code: <b>{selectedProduct?.productCode}</b>
                  </Typography>
                </Grid>
                <Grid sm={12} md={4} xs={12} lg={4}>
                  {selectedProduct?.productLink && selectedProduct?.productLink.includes("http") && <Typography gutterBottom variant="body2" component="div">
                    To view/visit product{" "}
                    <a href={new URL(selectedProduct?.productLink?.toString())} target="_blank">
                      {" "}
                      click here
                    </a>
                  </Typography>}
                </Grid>
              </Grid>

              <Typography variant="body2" color={"#fff"}>
                Description: <b>{selectedProduct?.description}</b>
              </Typography>
              <Typography variant="body2" color={"#fff"} sx={{ mt: "0.5rem" }}>
                Country: <b>{currentCountry}</b>
              </Typography>
            </CardContent>
          </CardActionArea>
          {/* <CardActions>
            <Button size="small" variant="outlined" color="primary" disabled>
              Selected
            </Button>
          </CardActions> */}
        </Card>
      </Grid>

      <Divider sx={{ my: 1 }} />
      <Typography variant="h6" align="center">
        Select the package from below list:
      </Typography>
      {/* <Typography variant="h6" align="center">
        Select the package from below list
      </Typography> */}
      {serverList &&
        serverList?.map((row, serIndex) => {
          
          return (
            <Accordion
              expanded={expanded == serIndex}
              sx={{ my: 1, border: "1px solid", borderColor: "gray" }}
              onChange={() => {
                setExpanded(serIndex == expanded ? -1 : serIndex);
              }}
              key={`${serIndex}_a`}
            >
              <AccordionSummary
                sx={{
                  backgroundColor: "#94919130",
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                key={`${serIndex}_as`}
              >
                {serverList?.length > 1 && <Typography sx={{ width: "33%", flexShrink: 0 }}>{row?.title}</Typography>}
                <Typography sx={{ color: row?.pack?.shortDesc ? "#6b8bf8" : "text.secondary" }}>
                  <b>{row?.pack?.shortDesc || "<No Package Selected>"}</b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails key={`${serIndex}_ad`}>
                <Grid spacing={3} sx={{ marginTop: "1rem" }} key={`${serIndex}_ag1`}>
                  {row?.packageList &&
                    row?.packageList?.map((pack, pi) => (
                      <Grid sm={12} md={12} key={`${serIndex}_${pi}_rg1`}>
                        <Card sx={{ display: "flex", width: "100%" }} key={`${serIndex}_${pi}_ca`}>
                          <CardActionArea
                            sx={{
                              backgroundColor: row?.pack?._id == pack?._id ? "#6b8bf8" : "#fff",
                              color: row?.pack?._id == pack?._id ? "#fff" : "#000",
                            }}
                            key={`${serIndex}_${pi}_caa`}
                          >
                            <CardContent key={`${serIndex}_${pi}_cca`}>
                              <Typography gutterBottom variant="h5" component="div">
                                {pack?.name}
                              </Typography>

                              <Grid
                                container
                                spacing={2}
                                sx={{ paddingX: "1rem", marginY: "1rem" }}
                                key={`${serIndex}_${pi}_ccag1`}
                              >
                                <Grid sm={12} md={4} xs={12} lg={4} key={`${serIndex}_${pi}_ccag2`}>
                                  <Typography gutterBottom variant="body1" component="div">
                                    Price:{" "}
                                    <b>
                                      {getCurrencySymbol(pack?.currencyCode || "USD")}{" "}
                                      {Number(pack?.price || 0)?.toFixed(2) || 0}
                                    </b>
                                  </Typography>
                                </Grid>
                                <Grid sm={12} md={4} xs={12} lg={4} key={`${serIndex}_${pi}_ccag3`}>
                                  <Typography gutterBottom variant="body1" component="div">
                                    Duration: <b> {pack?.durationDays || 0} Days</b>
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Typography
                                variant="body2"
                                color={row?.pack?._id == pack?._id ? "#fff" : "text.secondary"}
                              >
                                Description: {pack?.description}
                              </Typography>
                            </CardContent>
                          </CardActionArea>
                          <CardActions key={`${serIndex}_${pi}_cas`}>
                            <Button
                              size="small"
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                handlePackageSelect(serIndex, pack);
                              }}
                              disabled={row?.pack?._id == pack?._id}
                            >
                              {row?.pack?._id == pack?._id ? "Selected" : "Select"}
                            </Button>
                          </CardActions>
                        </Card>
                        <Divider sx={{ my: 1 }} />
                      </Grid>
                    ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </>
  );
}

export default PackageSelect;
