import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import {
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import CustomToast from "../CustomToast";
import {
  AccountBalance,
  Assignment,
  Business,
  Diversity2,
  EditNoteOutlined,
  ErrorOutline,
  ExpandLess,
  ExpandMore,
  Layers,
  LibraryBooks,
  MiscellaneousServices,
  RadioButtonChecked,
} from "@mui/icons-material";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
      style={{
        position: "fixed",
        bottom: 0,
        marginLeft: "25%",
        marginRight: "auto",
        transform: "translate(50%, 0)",
      }}
    >
      {"Copyright © "}
      <a href={window?.location.href} style={{ textDecoration: "none" }}>
        {window?.location?.hostname || "Blue Bricks Licensing"}
      </a>
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "#e4e8ec",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    top: 64, // Adjust based on AppBar height
    whiteSpace: "nowrap",
    height: "calc(100% - 64px)", // Adjust based on AppBar height
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const DocsLayout = ({ children }) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentPath, setCurrentPath] = React.useState("Overview >");

  const [openMenus, setOpenMenus] = React.useState({});

  const handleDrawerMenuToggle = (menuId) => {
    setOpenMenus((prevOpenMenus) => ({
      ...prevOpenMenus,
      [menuId]: !prevOpenMenus[menuId],
    }));
  };

  const menuItems = [
    {
      id: "overview",
      icon: <Assignment fontSize="small" />,
      label: "Overview",
      path: "/docs",
      subItems: [],
    },
    {
      id: "team",
      icon: <Diversity2 fontSize="small" />,
      label: "Team Management",
      path: "/docs/teams",
      subItems: [],
    },
    {
      id: "org",
      icon: <Business fontSize="small" />,
      label: "Organization/clients",
      path: "/docs/orgs",
      subItems: [],
    },
    {
      id: "prodpack",
      icon: <Layers fontSize="small" />,
      label: "Products & Packages",
      path: "",
      subItems: [
        { id: "prods1", label: "Products", path: "/docs/products" },
        { id: "packs1", label: "Packages", path: "/docs/packages" },
      ],
    },
    {
      id: "req",
      icon: <EditNoteOutlined fontSize="small" />,
      label: "Requests",
      path: "",
      subItems: [
        { id: "newsub1", label: "New Requests", path: "/docs/newrequests" },
        { id: "renewsub1", label: "Re-New Requests", path: "/docs/renewrequests" },
      ],
    },
    {
      id: "stripe",
      icon: <AccountBalance fontSize="small" />,
      label: "Stripe Integration",
      path: "/docs/stripe-integration",
      subItems: [],
    },
    {
      id: "sdk",
      icon: <MiscellaneousServices fontSize="small" />,
      label: "License SDKs",
      path: "",
      subItems: [
        { id: "oversdk", label: "Overview", path: "/docs/sdk" },
        { id: "jsts", label: "JS & TS", path: "/docs/sdk/js-ts" },
        { id: "java", label: "JAVA", path: "/docs/sdk/java" },
        { id: "python", label: "Python", path: "/docs/sdk/python" },
        { id: "csharp", label: "C-Sharp (C#)", path: "/docs/sdk/c-sharp" },
        { id: "android", label: "Android", path: "/docs/sdk/android" },
        { id: "ios", label: "Ios", path: "/docs/sdk/ios" },
      ],
    },
    {
      id: "ExposedApi",
      icon: <LibraryBooks fontSize="small" />,
      label: "Exposed API",
      path: "",
      subItems: [
        { id: "pl", label: "Get Package List", path: "/docs/package-listing-api" },
        { id: "gpl", label: "Get Payment Link", path: "/docs/payment-link-api" },
      ],
    },
    {
      id: "report",
      icon: <LibraryBooks fontSize="small" />,
      label: "Report API",
      path: "/docs/report-api",
      subItems: [],
    },
    {
      id: "errors",
      icon: <ErrorOutline fontSize="small" />,
      label: "Error codes",
      path: "/docs/errors",
      subItems: [],
    },
  ];

  return (
    <main className="">
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              justifyContent: "space-between",
            }}
          >
            {/* provide version selection option */}

            <img src="/Valydate4u.png" style={{ width: "200px" }} />

            <Typography color="blue" variant="caption">
              v1.0
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <List component="nav">
            {menuItems.map((item) => (
              <div key={item.id}>
                <ListItemButton
                  onClick={(e) => {
                    if (item.path) {
                      setCurrentPath(`${item.label} >`);
                      navigate(item.path);
                    }
                    handleDrawerMenuToggle(item.id);
                  }}
                >
                  <ListItemIcon
                    sx={{
                      width: "30px",
                      minWidth: "30px",
                      color: window.location.pathname == item.path ? "#327adf" : "default",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: 15,
                      fontWeight: "medium",
                      lineHeight: "20px",
                    }}
                    primary={item.label}
                    sx={{ color: window.location.pathname == item.path ? "#327adf" : "default" }}
                  />
                  {item.subItems?.length === 0 ? <></> : openMenus[item.id] ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openMenus[item.id]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.subItems.map((subItem) => (
                      <ListItemButton
                        key={subItem.id}
                        sx={{ pl: 5 }}
                        onClick={() => {
                          setCurrentPath(`${item.label} > ${subItem.label} >`);
                          navigate(subItem.path);
                        }}
                      >
                        <ListItemIcon sx={{ width: "30px", minWidth: "30px" }}>
                          <RadioButtonChecked
                            fontSize="small"
                            sx={{ color: window.location.pathname == subItem.path ? "#327adf" : "default" }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={subItem.label}
                          sx={{ color: window.location.pathname == subItem.path ? "#327adf" : "default" }}
                        />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>

                <Divider sx={{ my: 1 }} />
              </div>
            ))}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ my: 4 }}>
            <CustomToast />
            <Typography variant="caption" color="blueviolet" sx={{ "&:hover": { fontWeight: "600" } }}>
              {currentPath}
            </Typography>
            <Box sx={{ mt: 2 }}>{children}</Box>

            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </main>
  );
};

export default DocsLayout;
